import React, { useState, useRef } from "react";
import { useLocation } from 'react-router-dom';
import Header from "./Header";
import Footer from "./Footer";
import { saveUserData } from '../services/databaseService';
import "./InsuranceForm.css";

const coverDetails = {
  1500: {
    title: "Silver Plan",
    price: "Gh₵ 1,500",
    features: [
      "Disability (Accident): Gh₵ 3,000",
      "Hospitalization Benefit: Gh₵ 50/day",
      "Monthly Premium: Gh₵ 4.32",
      "Annual Premium: Gh₵ 51.84",
    ],
  },
  2500: {
    title: "Gold Plan",
    price: "Gh₵ 2,500",
    features: [
      "Disability (Accident): Gh₵ 5,000",
      "Hospitalization Benefit: Gh₵ 100/day",
      "Monthly Premium: Gh₵ 8.07",
      "Annual Premium: Gh₵ 96.79",
    ],
  },
  5000: {
    title: "Platinum Plan",
    price: "Gh₵ 5,000",
    features: [
      "Disability (Accident): Gh₵ 10,000",
      "Hospitalization Benefit: Gh₵ 200/day",
      "Monthly Premium: Gh₵ 16.13",
      "Annual Premium: Gh₵ 193.58",
    ],
  },
};

const familyOptions = {
  family_option_1: [
    "Age band: 1-69yrs | Life Cover: Gh₵ 1,000 | Monthly: Gh₵ 2.63 | Annual: Gh₵ 31.58",
    "Age band: 70-74yrs | Life Cover: Gh₵ 1,000 | Monthly: Gh₵ 13.25 | Annual: Gh₵ 159.02",
    "Age band: 75-84yrs | Life Cover: Gh₵ 1,000 | Monthly: Gh₵ 24.18 | Annual: Gh₵ 290.16",
  ],
  family_option_2: [
    "Age band: 1-69yrs | Life Cover: Gh₵ 2,000 | Monthly: Gh₵ 5.26 | Annual: Gh₵ 63.15",
    "Age band: 70-74yrs | Life Cover: Gh₵ 2,000 | Monthly: Gh₵ 26.50 | Annual: Gh₵ 318.03",
    "Age band: 75-84yrs | Life Cover: Gh₵ 2,000 | Monthly: Gh₵ 48.36 | Annual: Gh₵ 580.32",
  ],
};

const GHACardMask = "GHA-XXXXXXXXX-X";

function InsuranceForm() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const selectedPlan = searchParams.get('plan');
  const selectedTier = searchParams.get('tier'); // NEW LINE: get tier from URL

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    dateOfBirth: "",
    dateOfPayment: "",
    beneficiaryName: "",
    beneficiaryPhoneNumber: "",
    payeeGhanaCardNumber: "",
    beneficiaryGhanaCardNumber: "",
    coverOption: selectedPlan === 'silver' ? '1500' : 
                 selectedPlan === 'gold' ? '2500' : 
                 selectedPlan === 'platinum' ? '5000' : "",
    paymentMethod: "credit_card",
    coverDetailsOption: selectedTier === 'tier1' ? 'family_option_1' : 
                        selectedTier === 'tier2' ? 'family_option_2' : "",
  });

  const [focusedInput, setFocusedInput] = useState(null);
  const [payeeTypedCount, setPayeeTypedCount] = useState(0);
  const [beneficiaryTypedCount, setBeneficiaryTypedCount] = useState(0);
  const [message, setMessage] = useState('');

  const payeeRef = useRef(null);
  const beneficiaryRef = useRef(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handlePhoneNumberChange = (e, fieldName) => {
    // Accept numbers only
    const value = e.target.value.replace(/\D/g, '');
    setFormData(prev => ({ ...prev, [fieldName]: value }));
  };

  const handleGhanaCardFocus = (e, fieldName, typedCountSetter) => {
    setFocusedInput(fieldName);
    if (!formData[fieldName]) {
      setFormData(prev => ({ ...prev, [fieldName]: GHACardMask }));
      setTimeout(() => {
        if (e.target.setSelectionRange) {
          e.target.setSelectionRange(4,4);
        }
      }, 0);
      typedCountSetter(0);
    }
  };

  const handleGhanaCardBlur = (e, fieldName, typedCount) => {
    setFocusedInput(null);
    if (formData[fieldName] === GHACardMask && typedCount === 0) {
      setFormData(prev => ({ ...prev, [fieldName]: "" }));
    }
  };

  const insertCharAt = (str, index, char) => {
    return str.slice(0, index) + char + str.slice(index+1);
  };

  const handleGhanaCardKeyDown = (e, fieldName, typedCount, typedCountSetter) => {
    const key = e.key;
    if (key === 'Backspace') {
      if (typedCount > 0) {
        e.preventDefault();
        let newVal = formData[fieldName];
        let removeIndex = typedCount === 10 ? 14 : (4 + (typedCount - 1));
        newVal = insertCharAt(newVal, removeIndex, 'X');
        setFormData(prev => ({ ...prev, [fieldName]: newVal }));
        typedCountSetter(typedCount - 1);

        setTimeout(() => {
          const input = (fieldName === 'payeeGhanaCardNumber') ? payeeRef.current : beneficiaryRef.current;
          if (input && input.setSelectionRange) {
            let cursorPos = typedCount === 10 ? 14 : (4 + (typedCount - 1));
            if (cursorPos < 4) cursorPos = 4;
            input.setSelectionRange(cursorPos, cursorPos);
          }
        }, 0);
      }
    }
  };

  const handleGhanaCardChange = (e, fieldName, typedCount, typedCountSetter) => {
    const input = e.target;
    const oldVal = formData[fieldName];
    const newVal = input.value;
    
    if (!newVal.startsWith("GHA-")) {
      setFormData(prev => ({ ...prev }));
      setTimeout(() => {
        if (input.setSelectionRange) {
          input.setSelectionRange(4,4);
        }
      }, 0);
      return;
    }

    const selectionStart = input.selectionStart;
    const diff = newVal.length - oldVal.length;

    if (diff > 0) {
      const insertedChars = newVal.slice(selectionStart - diff, selectionStart);
      const validChars = insertedChars.replace(/[^A-Za-z0-9]/g, '');
      if (validChars.length === 0) {
        setFormData(prev => ({ ...prev }));
        setTimeout(() => {
          if (input.setSelectionRange) {
            input.setSelectionRange(4 + typedCount, 4 + typedCount);
          }
        }, 0);
        return;
      }
      const char = validChars[0];
      if (typedCount < 9) {
        let replaceIndex = 4 + typedCount; 
        let newStr = insertCharAt(oldVal, replaceIndex, char);
        setFormData(prev => ({ ...prev, [fieldName]: newStr }));
        typedCountSetter(typedCount + 1);

        setTimeout(() => {
          if (input.setSelectionRange) {
            input.setSelectionRange(4 + (typedCount + 1), 4 + (typedCount + 1));
          }
        }, 0);

      } else if (typedCount === 9) {
        let newStr = insertCharAt(oldVal, 14, char);
        setFormData(prev => ({ ...prev, [fieldName]: newStr }));
        typedCountSetter(typedCount + 1);

        setTimeout(() => {
          if (input.setSelectionRange) {
            input.setSelectionRange(15, 15);
          }
        }, 0);

      } else {
        setFormData(prev => ({ ...prev }));
        setTimeout(() => {
          if (input.setSelectionRange) {
            input.setSelectionRange(15, 15);
          }
        }, 0);
      }
    }
  };

  const shouldFloat = (fieldName) => {
    return focusedInput === fieldName || 
           (formData[fieldName] && formData[fieldName] !== GHACardMask);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const dataToSave = {
      ...formData,
      submittedAt: new Date().toISOString() // or you can store a timestamp
    };
    
    try {
      const docId = await saveUserData(dataToSave);
      setMessage(`Application submitted and payment processed successfully! Reference ID: ${docId}`);
      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        dateOfBirth: "",
        dateOfPayment: "",
        beneficiaryName: "",
        beneficiaryPhoneNumber: "",
        payeeGhanaCardNumber: "",
        beneficiaryGhanaCardNumber: "",
        coverOption: "",
        paymentMethod: "credit_card",
        coverDetailsOption: "",
      });
    } catch (error) {
      console.error('Error submitting form:', error);
      setMessage('An error occurred. Please try again.');
    }
  };

  return (
    <div className="insurance-form-page">
      <Header />
      <div className={`form-content ${formData.coverOption ? "slightly-shifted" : ""}`}>
        <div className="form-container">
          <div className="form-card">
            <h1>Nketewade Insurance</h1>
            <p>Please provide the following information to set up your insurance.</p>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <input
                  type="text"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  required
                  placeholder=" "
                />
                <label>First Name</label>
              </div>
              <div className="form-group">
                <input
                  type="text"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  required
                  placeholder=" "
                />
                <label>Last Name</label>
              </div>
              <div className="form-group">
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  placeholder=" "
                />
                <label>Email</label>
              </div>
              <div className="form-group">
                <input
                  type="tel"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={(e) => handlePhoneNumberChange(e, 'phoneNumber')}
                  required
                  placeholder=" "
                  maxLength="11"
                />
                <label>Phone Number</label>
              </div>
              <div className="form-group">
                <input
                  type="date"
                  name="dateOfBirth"
                  value={formData.dateOfBirth}
                  onChange={handleChange}
                  required
                  placeholder="dd/mm/yyyy"
                />
                <label>Date of Birth</label>
              </div>
              <div className="form-group">
                <input
                  type="date"
                  name="dateOfPayment"
                  value={formData.dateOfPayment}
                  onChange={handleChange}
                  required
                  placeholder="dd/mm/yyyy"
                />
                <label>Preferred Payment Date</label>
              </div>
              <div className="form-group">
                <input
                  type="text"
                  name="beneficiaryName"
                  value={formData.beneficiaryName}
                  onChange={handleChange}
                  required
                  placeholder=" "
                />
                <label>Beneficiary Name</label>
              </div>
              <div className="form-group">
                <input
                  type="tel"
                  name="beneficiaryPhoneNumber"
                  value={formData.beneficiaryPhoneNumber}
                  onChange={(e) => handlePhoneNumberChange(e, 'beneficiaryPhoneNumber')}
                  required
                  placeholder=" "
                />
                <label>Beneficiary Phone Number</label>
              </div>

              {/* Payee Ghana Card Number */}
              <div className="form-group">
                <input
                  ref={payeeRef}
                  type="text"
                  name="payeeGhanaCardNumber"
                  value={formData.payeeGhanaCardNumber}
                  onFocus={(e) => handleGhanaCardFocus(e, 'payeeGhanaCardNumber', setPayeeTypedCount)}
                  onBlur={(e) => handleGhanaCardBlur(e, 'payeeGhanaCardNumber', payeeTypedCount)}
                  onKeyDown={(e) => handleGhanaCardKeyDown(e, 'payeeGhanaCardNumber', payeeTypedCount, setPayeeTypedCount)}
                  onChange={(e) => handleGhanaCardChange(e, 'payeeGhanaCardNumber', payeeTypedCount, setPayeeTypedCount)}
                  placeholder=" "
                />
                <label className={shouldFloat('payeeGhanaCardNumber') ? 'active' : ''}>Payee Ghana Card Number</label>
              </div>

              {/* Beneficiary Ghana Card Number */}
              <div className="form-group">
                <input
                  ref={beneficiaryRef}
                  type="text"
                  name="beneficiaryGhanaCardNumber"
                  value={formData.beneficiaryGhanaCardNumber}
                  onFocus={(e) => handleGhanaCardFocus(e, 'beneficiaryGhanaCardNumber', setBeneficiaryTypedCount)}
                  onBlur={(e) => handleGhanaCardBlur(e, 'beneficiaryGhanaCardNumber', beneficiaryTypedCount)}
                  onKeyDown={(e) => handleGhanaCardKeyDown(e, 'beneficiaryGhanaCardNumber', beneficiaryTypedCount, setBeneficiaryTypedCount)}
                  onChange={(e) => handleGhanaCardChange(e, 'beneficiaryGhanaCardNumber', beneficiaryTypedCount, setBeneficiaryTypedCount)}
                  placeholder=" "
                />
                <label className={shouldFloat('beneficiaryGhanaCardNumber') ? 'active' : ''}>Beneficiary Ghana Card Number</label>
              </div>

              {/* Cover Option Selector */}
              <div className="form-group cover-option-group">
                <select
                  name="coverOption"
                  value={formData.coverOption}
                  onChange={handleChange}
                  required
                >
                  <option value="" disabled>Select Cover Option</option>
                  <option value="1500">Silver (Gh₵ 1,500)</option>
                  <option value="2500">Gold (Gh₵ 2,500)</option>
                  <option value="5000">Platinum (Gh₵ 5,000)</option>
                </select>
                <label>Select Cover Option</label>
                {formData.coverOption && (
                  <div className="cover-details">
                    <h3>{coverDetails[formData.coverOption].title}</h3>
                    <p><strong>Price:</strong> {coverDetails[formData.coverOption].price}</p>
                    <ul>
                      {coverDetails[formData.coverOption].features.map((feature, index) => (
                        <li key={index}>{feature}</li>
                      ))}
                    </ul>

                    {formData.coverDetailsOption && (
                      <>
                        <hr />
                        <ul className="family-addon-details">
                          {familyOptions[formData.coverDetailsOption].map((detail, idx) => (
                            <li key={idx}>{detail}</li>
                          ))}
                        </ul>
                      </>
                    )}
                  </div>
                )}
              </div>
              
              <div className="form-group cover-option-group">
                <select
                  name="coverDetailsOption"
                  value={formData.coverDetailsOption}
                  onChange={handleChange}
                  disabled={!formData.coverOption}
                  required
                >
                  <option value="" disabled>Select Tier</option>
                  <option value="family_option_1">Tier 1</option>
                  <option value="family_option_2">Tier 2</option>
                </select>
                <label>Cover Option Details</label>
              </div>

              <div className="payment-section">
                <h2>Payment Method</h2>
                <div className="payment-methods">
                  <label className={formData.paymentMethod === "credit_card" ? "active" : ""}>
                    <input
                      type="radio"
                      name="paymentMethod"
                      value="credit_card"
                      checked={formData.paymentMethod === "credit_card"}
                      onChange={handleChange}
                    />
                    <span className="icon">💳</span> Credit Card
                  </label>
                  <label className={formData.paymentMethod === "momo" ? "active" : ""}>
                    <input
                      type="radio"
                      name="paymentMethod"
                      value="momo"
                      checked={formData.paymentMethod === "momo"}
                      onChange={handleChange}
                    />
                    <span className="icon">📱</span> Mobile Money
                  </label>
                </div>
              </div>
              <button type="submit" className="submit-btn">
                Place Order with {formData.paymentMethod === "credit_card" ? "Credit Card" : "Mobile Money"}
              </button>
            </form>
            {message && <p className="message">{message}</p>}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default InsuranceForm;
