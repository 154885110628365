import React, { useState, useRef } from "react";
import { Link } from 'react-router-dom';
import Header from "./Header";
import Footer from "./Footer";
import "./CoverOptions.css";

const mainLifeCover = [
  {
    type: "Silver",
    price: "1,500",
    features: [
      { title: "Disability (Accident)", value: "GH₵ 3,000" },
      { title: "Hospitalization Benefit", value: "GH₵ 50/day" },
      { title: "Monthly Premium", value: "GH₵ 4.32" },
      { title: "Annual Premium", value: "GH₵ 51.84" },
    ],
  },
  {
    type: "Gold",
    price: "2,500",
    features: [
      { title: "Disability (Accident)", value: "GH₵ 5,000" },
      { title: "Hospitalization Benefit", value: "GH₵ 100/day" },
      { title: "Monthly Premium", value: "GH₵ 8.07" },
      { title: "Annual Premium", value: "GH₵ 96.79" },
    ],
  },
  {
    type: "Platinum",
    price: "5,000",
    features: [
      { title: "Disability (Accident)", value: "GH₵ 10,000" },
      { title: "Hospitalization Benefit", value: "GH₵ 200/day" },
      { title: "Monthly Premium", value: "GH₵ 16.13" },
      { title: "Annual Premium", value: "GH₵ 193.58" },
    ],
  },
];

const tier1Data = [
  "Age band: 1-69yrs | Life Cover: Gh₵ 1,000 | Monthly: Gh₵ 2.63 | Annual: Gh₵ 31.58",
  "Age band: 70-74yrs | Life Cover: Gh₵ 1,000 | Monthly: Gh₵ 13.25 | Annual: Gh₵ 159.02",
  "Age band: 75-84yrs | Life Cover: Gh₵ 1,000 | Monthly: Gh₵ 24.18 | Annual: Gh₵ 290.16",
];

const tier2Data = [
  "Age band: 1-69yrs | Life Cover: Gh₵ 2,000 | Monthly: Gh₵ 5.26 | Annual: Gh₵ 63.15",
  "Age band: 70-74yrs | Life Cover: Gh₵ 2,000 | Monthly: Gh₵ 26.50 | Annual: Gh₵ 318.03",
  "Age band: 75-84yrs | Life Cover: Gh₵ 2,000 | Monthly: Gh₵ 48.36 | Annual: Gh₵ 580.32",
];

function parseTierLine(line) {
  const parts = line.split('|').map(p => p.trim());
  const obj = {};
  parts.forEach((part) => {
    const [key, val] = part.split(':').map(x => x.trim());
    if (key.toLowerCase().includes("age band")) obj.ageBand = val;
    if (key.toLowerCase().includes("life cover")) obj.lifeCover = val;
    if (key.toLowerCase().includes("monthly")) obj.monthly = val;
    if (key.toLowerCase().includes("annual")) obj.annual = val;
  });
  return obj;
}

const TierTable = ({ tier }) => {
  const data = tier === "tier1" ? tier1Data.map(parseTierLine) : tier2Data.map(parseTierLine);

  return (
    <table className="modal-table">
      <thead>
        <tr>
          <th>Age Band</th>
          <th>Life Cover</th>
          <th>Monthly</th>
          <th>Annual</th>
        </tr>
      </thead>
      <tbody>
        {data.map((row, idx) => (
          <tr key={idx}>
            <td>{row.ageBand}</td>
            <td>{row.lifeCover}</td>
            <td>{row.monthly}</td>
            <td>{row.annual}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const TierSelection = ({ selectedPlan }) => {
  return (
    <div className="tier-selection-section">
      <h1 className="page-title">Explore Your Tier Options</h1>
      <p className="page-subtitle">
        You are now viewing additional details for the <strong>{selectedPlan}</strong> plan. <br/>
        Please select a tier that best suits your family’s age group and budget.
      </p>

      <div className="pricing-grid-container">
        <div className="pricing-grid family">
          {/* Tier 1 Card */}
          <div className="pricing-card">
            <div className="card-content">
              <h3 className="plan-type">Tier 1</h3>
              <p className="tier-short-desc">Base coverage with Life Cover of Gh₵ 1,000.</p>
              <TierTable tier="tier1" />
              <Link 
                to={`/purchase?plan=${selectedPlan.toLowerCase()}&tier=tier1`} 
                className="select-button continue-btn"
              >
                Select Tier 1
              </Link>
            </div>
          </div>
          {/* Tier 2 Card */}
          <div className="pricing-card">
            <div className="card-content">
              <h3 className="plan-type">Tier 2</h3>
              <p className="tier-short-desc">Enhanced coverage with Life Cover of Gh₵ 2,000.</p>
              <TierTable tier="tier2" />
              <Link 
                to={`/purchase?plan=${selectedPlan.toLowerCase()}&tier=tier2`} 
                className="select-button continue-btn"
              >
                Select Tier 2
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const CoverOptions = () => {
  const [coverType] = useState("main");
  const [selectedPlan, setSelectedPlan] = useState(null);
  const tierSectionRef = useRef(null);

  const handleViewDetails = (plan) => {
    setSelectedPlan(plan);
    setTimeout(() => {
      tierSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }, 100);
  };

  return (
    <>
      <Header />
      <div className="cover-options-page">
        <div className="cover-options-container">
          <h1 className="page-title">Choose your plan</h1>
          <p className="page-subtitle">
            Choose the perfect plan to secure your family’s future with confidence.
          </p>

          <div className="pricing-grid-container">
            <div className={`pricing-grid ${coverType === "family" ? "family" : ""}`}>
              {mainLifeCover.map((plan, index) => (
                <div className="pricing-card" key={index}>
                  <div className="card-content">
                    <h3 className="plan-type">{plan.type}</h3>
                    <div className="price">
                      <span className="currency">GH₵</span>
                      <span className="amount">{plan.price}</span>
                    </div>
                    <ul className="features-list">
                      {plan.features.map((feature, i) => (
                        <li key={i}>
                          <span className="feature-title">{feature.title}</span>
                          <span className="feature-value">{feature.value}</span>
                        </li>
                      ))}
                    </ul>
                    <Link 
                      to={`/purchase?plan=${plan.type.toLowerCase()}`} 
                      className="select-button plan-btn-top"
                    >
                      Select Plan
                    </Link>
                    <a 
                      href="#" 
                      className="select-button plan-btn-bottom" 
                      onClick={(e) => {e.preventDefault(); handleViewDetails(plan.type);}}
                    >
                      View Details
                    </a>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div ref={tierSectionRef}></div>
        {selectedPlan && <TierSelection selectedPlan={selectedPlan} />}
      </div>
      <Footer />
    </>
  );
};

export default CoverOptions;
