import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <footer>
      <div className="container footer-container">
        <div className="footer-section about">
          <h3>About Us</h3>
          <p>Nketewade Insurance: Ketewa Biara Nsua</p>
        </div>
        <div className="footer-section contact">
          <h3>Contact</h3>
          <p>
            Email: <a href="mailto:hello@summerhealth.io">hello@summerhealth.io</a>
          </p>
          <p>
            Phone: <a href="tel:+233500490065">+233 500 490 065</a>
          </p>
        </div>
        <div className="footer-section follow-us">
          <h3>Follow Us</h3>
          <div className="social-links">
            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
              Facebook
            </a>
            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
              Twitter
            </a>
            <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
              LinkedIn
            </a>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; <span id="currentYear">{new Date().getFullYear()}</span> Nketewade Insurance. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
