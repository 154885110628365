import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './components/HomePage'; 
import Header from './components/Header';
import Footer from './components/Footer';
import InsuranceForm from './components/InsuranceForm';
import CoverOptions from './components/CoverOptions';


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/purchase" element={<InsuranceForm />} />
        <Route path="/pricing" element={<CoverOptions/>} />
   
      </Routes>
    </Router>
  );
}

export default App;