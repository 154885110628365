import React, { useState } from "react";
import Header from "./Header"; // Import the Header component
import Footer from "./Footer"; // Import the Footer component
import "./HomePage.css";

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="faq-item">
      <div
        className="faq-question"
        onClick={() => setIsOpen(!isOpen)}
        role="button"
        aria-expanded={isOpen}
        tabIndex={0}
      >
        {question}
        <span className={`faq-icon ${isOpen ? "open" : ""}`}>{isOpen ? "-" : "+"}</span>
      </div>
      <div className={`faq-answer ${isOpen ? "visible" : ""}`} style={{ maxHeight: isOpen ? "200px" : "0" }}>
        {answer}
      </div>
    </div>
  );
};

const HomePage = () => {
  const faqData = [
    { question: "What is life insurance?", answer: "Life insurance is a contract between an individual and an insurance company..." },
    { question: "Why is life insurance necessary?", answer: "Life insurance provides financial protection for your loved ones..." },
    { question: "How does life insurance work?", answer: "Policyholders pay regular premiums, and in return, the insurer provides..." },
  ];

  return (
    <div className="home-page">
      <Header /> {/* Use the Header component */}
      <header className="hero">
        <div className="container">
          <div className="hero-content">
            <h1>Redefining the boundaries of financial security</h1>
            <p>Explore innovative life insurance solutions tailored for the African market. Protect your family's future today.</p>
            <div className="cta-buttons">
              <a href="/purchase" className="cta-button primary">
                Visit Our Insurance Portal
              </a>
              <a href="/pricing" className="cta-button secondary">
                Learn More
              </a>
            </div>
          </div>
        </div>
      </header>
      <section className="faq-section">
        <div className="container">
          <h2>Frequently Asked Questions</h2>
          <div className="faq-container">
            {faqData.map((item, index) => (
              <FAQItem key={index} question={item.question} answer={item.answer} />
            ))}
          </div>
        </div>
      </section>
      <Footer /> {/* Use the Footer component */}
    </div>
  );
};

export default HomePage;
