import React from "react";
import { Link } from "react-router-dom";
import "./Header.css"; // Import the CSS file for header-specific styles

const Header = () => {
  return (
    <nav className="navbar">
      <div className="container">
        <div className="logo">Nketewade Insurance</div>
        <div className="nav-links">
          <Link to="/" className="cta-button secondary">Home</Link>
          {/* <Link to="/contact" className="cta-button secondary">Contact</Link> */}
          <Link to="/pricing" className="cta-button secondary">
            Policy Options
          </Link>
          <Link to="/purchase" className="cta-button">
            Purchase an Insurance Plan
          </Link>
        </div>
      </div>
    </nav>
  );
};

export default Header;
